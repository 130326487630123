import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const WrapperSteps = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

export const Step = styled.div<{ active: boolean }>`
  ${({ theme, active }) => css`
    width: 38px;
    height: 5px;
    background: ${active
      ? theme.colors.primary200
      : theme.colors.menu_detail_light};
    border-radius: 6px;
    transition: background 0.3s ease-in-out;
  `};
`;

export const ArrowButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 8px;
    cursor: pointer;

    & > img {
      width: 20px;
      height: 18px;
    }

    & > span {
      line-height: 17px;
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.text_extra_light};
    }

    &:disabled {
      opacity: 0.6;
    }
  `}
`;
