import React, { Dispatch } from "react";

import ArrowRight from "assets/icons/arrow-right.svg";

import * as S from "./styles";

type ProgressBarProps = {
  steps: number;
  currentStep: number;
  type?: 'onboarding' | 'tour';
  setCurrentStep: Dispatch<React.SetStateAction<number>>;
  endOnboarding?: () => void;
};

export default function ProgressBar({
  steps,
  currentStep,
  type = 'onboarding',
  setCurrentStep,
  endOnboarding,
}: ProgressBarProps) {

  function handlePrevArrow() {
    if (currentStep <= 1) return;
    setCurrentStep((prevState) => prevState - 1);
  }

  function handleNextArrow() {
    if (currentStep < steps) {
      setCurrentStep((prevState) => prevState + 1);
    } else if (currentStep === steps) {
      endOnboarding?.();
    }
  }

  return (
    <S.Container>
      <S.ArrowButton disabled={currentStep <= 1} onClick={handlePrevArrow}>
        <img
          src={ArrowRight}
          alt="voltar"
          style={{ transform: "rotate(180deg)" }}
        />
        {type === 'onboarding' && <span>Voltar</span>}
      </S.ArrowButton>

      <S.WrapperSteps>
        {Array.from(Array(steps).keys()).map((_, index) => (
          <S.Step active={index + 1 === currentStep} key={index} />
        ))}
      </S.WrapperSteps>

      <S.ArrowButton onClick={currentStep === steps ? endOnboarding : handleNextArrow}>
        {type === 'onboarding' && <span>{currentStep === steps ? 'Finalizar' : 'Próximo'}</span>}
        <img src={ArrowRight} alt="próximo" />
      </S.ArrowButton>
    </S.Container>
  );
}
