/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, ChangeEvent } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { HiOutlineUser } from "react-icons/hi";
import { MdCreate } from "react-icons/md";

import { ArrowButton } from "components/ArrowButton";
import { ModalRight } from "components/ModalRight";
import { ModuleCard } from "components/ModuleCard";
import { ProfileNextServices } from "./ProfileNextServices";
import { ProfileCertifications } from "./ProfileCertifications";
import { PersonMode } from "../EditProfile/PersonMode";
import { RolePresentation } from "components/RolePresentation";
import { HasPermissionAlert } from "components/Permission";
import { CardProfileUnderAnalysis } from "components/CardProfileUnderAnalysis";

import EditButtonSvg from "assets/icons/edit-wrapper.svg";
import AvaliationIcons from "assets/icons/avaliations-icon.svg";
import ProfileBanner from "assets/banner/profile-banner.png";
// import ProfitzIcon from "assets/icons/profitz-icon.svg";
import TeamsSVG from "assets/icons/permission/teams-icon.svg";

import { useAuth, User } from "hooks/auth";
import { useToast } from "hooks/toast";
import { useAccount } from "hooks/permission/account";
import { api } from "services/api";

import * as S from "./styles";

export function Profile() {
  const { user, updateUser } = useAuth();
  const { whoami, handleSetWhoami } = useAccount();
  const [editModalVisible, setEditModalVisible] = useState(false);
  const history = useHistory();
  const { addToast } = useToast();
  const theme = useTheme();

  function handleNavigateEditProfile(userIsApproved: boolean) {
    setEditModalVisible(true);

    if (user.underAnalysis) {
      addToast({
        title: "Falta pouco!!",
        description: "Seu cadastro está sendo analisado pelo time Profiz",
        type: "info",
      });
    } else if (!userIsApproved) {
      history.push("/complete-profile");
    }
  }

  async function getUser() {
    const { data } = await api.get("/users/me");
    updateUser(data);
  }

  useEffect(() => {
    getUser();
  }, []);

  function handleUpdateAvatarProfile(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      const file = event.target.files[0];

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, extension] = file.type.split("/");

      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = ({ target }) => {
        const base64 = target?.result as string;
        const splitedBase64 = base64.split(",")[1];

        api
          .put("/users/me/photo", {
            photo: {
              encode: splitedBase64,
              extensao: extension,
            },
          })
          .then((response) => {
            updateUser(response.data);

            if (whoami?.isMe) {
              const user: User = response.data;
              let accountOwner = user?.permissions?.accounts.find(
                (account) => account?.isMe
              );
              handleSetWhoami(accountOwner!);
            }

            addToast({
              title: "Sucesso",
              description: "Foto atualizada com sucesso",
              type: "success",
            });
          })
          .catch(() => {
            addToast({
              title: "Ops",
              description: "Erro ao atualizar a foto do perfil",
              type: "error",
            });
          });
      };

      reader.onerror = () => {
        addToast({
          title: "Ops",
          description: "Erro ao atualizar a foto do perfil",
          type: "error",
        });
      };
    }
  }

  return (
    <S.Container>
      <ModalRight
        isOpen={editModalVisible}
        handleToggleOpen={() => setEditModalVisible(!editModalVisible)}
      >
        <PersonMode toggleModal={() => setEditModalVisible(false)} />
      </ModalRight>

      <header style={{ position: "relative" }}>
        <S.Banner alt="banner" src={ProfileBanner} />

        <S.ContentArrowButtons>
          <ArrowButton />
        </S.ContentArrowButtons>

        <S.Edit onClick={() => handleNavigateEditProfile(user.profileApproved)}>
          <img src={EditButtonSvg} alt="editar o perfil" />
        </S.Edit>
      </header>

      {user.profileComplete && !user.profileApproved && (
        <CardProfileUnderAnalysis />
      )}

      <S.ProfileInfo>
        <S.WrapperImageProfile>
          {user?.photo ? (
            <img src={user.photo} alt="foto do usuário" />
          ) : (
            <HiOutlineUser color={theme.colors.menu_detail_light} size={30} />
          )}

          <label htmlFor="avatar">
            <MdCreate size={20} color={theme.colors.menu_detail_light} />

            <input
              disabled={user.underAnalysis}
              id="avatar"
              type="file"
              accept="image/*"
              onChange={handleUpdateAvatarProfile}
            />
          </label>
        </S.WrapperImageProfile>

        <div style={{ marginLeft: 15 }}>
          <h4>{user.name}</h4>

          <S.WrapperInfo>
            {!!user.address?.street ? (
              <span>
                {user.address?.city}, {user.address.state}
              </span>
            ) : (
              <Link to="/complete-profile">
                <span
                  style={{ textDecoration: "underline", fontSize: "1.4rem" }}
                >
                  Preencha seu perfil e tenha acesso completo ao app
                </span>
              </Link>
            )}
          </S.WrapperInfo>
        </div>
      </S.ProfileInfo>

      <RolePresentation />

      <S.WrapperRow>
        <S.WrapperCard>
          <HasPermissionAlert
            navigateTo="ratings"
            moduleHash="satisfaction-survey"
            actionHash="view"
          >
            <ModuleCard svg={AvaliationIcons} title="Avaliações" />
          </HasPermissionAlert>
        </S.WrapperCard>

        {/* <S.WrapperCard>
          <HasPermissionAlert
            navigateTo="/profitz-extract"
            grantPermission={user.profileComplete && user.profileApproved}
          >
            <ModuleCard
              svg={ProfitzIcon}
              title="Profitz"
              onClick={() => history.push("/profitz-extract")}
            />
          </HasPermissionAlert>
        </S.WrapperCard> */}

        <HasPermissionAlert
          navigateTo="/teams"
          grantPermission={user.profileComplete && user.profileApproved}
        >
          <ModuleCard svg={TeamsSVG} title="Equipes" />
        </HasPermissionAlert>
      </S.WrapperRow>

      <S.Content>
        <div>
          <S.WrapperCertifications>
            <ProfileCertifications />
          </S.WrapperCertifications>
        </div>
        <ProfileNextServices />
      </S.Content>
    </S.Container>
  );
}
