import styled, { css } from "styled-components";

type ModalProps = {
  isVisible: boolean;
};

export const ContainerOverlay = styled.aside<ModalProps>`
  ${({ theme, isVisible }) => css`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    display: ${isVisible ? "flex" : "none"};
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: ${theme.layers.alwaysOnTop};
  `}
`;

export const Card = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 480px;
    height: 275px;
    background-color: ${theme.colors.background};
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    flex-direction: column;
  `};
`;

export const CardHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `};
`;

export const SkipButton = styled.div`
  ${({ theme }) => css`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    font-family: ${theme.font.family};
    color: #fff;
    cursor: pointer;
  `};
`;

export const CardTexts = styled.div`
  ${({ theme }) => css`
  `};
`;

export const CardTitle = styled.div`
  ${({ theme }) => css`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    font-family: ${theme.font.family};
    color: #fff;
    text-align: center;
  `};
`;

export const CardDescription = styled.div`
  ${({ theme }) => css`
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    font-family: ${theme.font.family};
    color: #fff;
    text-align: center;
    margin-top: 20px;
  `};
`;
