import React, { useState } from "react";

import ProgressBar from "components/ProgressBar";
import { AlertModal } from "components/AlertModal";

import { WelcomeFirst } from "./WelcomeFirst";
import { WelcomeSecond } from "./WelcomeSecond";
// import { WelcomeThird } from "./WelcomeThird";
// import { WelcomeFourth } from "./WelcomeFourth";

import welcome1 from "./images/welcome1.png";
import welcome2 from "./images/welcome2.png";
// import welcome3 from "./images/welcome3.png";
// import welcome4 from "./images/welcome4.png";

import * as S from "./styles";

interface FirstTimePageProps {
  endOnboarding: () => void;
}

export default function FirstTimePage({ endOnboarding }: FirstTimePageProps) {
  const [step, setStep] = useState(1);
  const [alertModalVisible, setAlertModalVisible] = useState(false);

  function getOnboardingStep() {
    if (step === 1) {
      return <WelcomeFirst />;
    }

    if (step === 2) {
      return <WelcomeSecond />;
    }

    // if (step === 3) {
    //   return <WelcomeThird />;
    // }

    // if (step === 4) {
    //   return <WelcomeFourth />;
    // }
  }

  function handleChangeImageByStep() {
    if (step === 1) {
      return welcome1;
    }

    if (step === 2) {
      return welcome2;
    }

    // if (step === 3) {
    //   return welcome3;
    // }

    // if (step === 4) {
    //   return welcome4;
    // }
  }

  return (
    <S.Container>
      <AlertModal
        isVisible={alertModalVisible}
        title="Atenção"
        description="Você tem certeza que deseja pular o onboarding ?"
        action="choose"
        handleConfirm={() => {
          setAlertModalVisible(false);
          endOnboarding();
        }}
        onCloseModal={() => setAlertModalVisible(false)}
      />

      <S.ImageContent>
        <img
          src={handleChangeImageByStep()}
          alt="Imagem Welcome"
          height={"100%"}
          width={"100%"}
        />
      </S.ImageContent>

      <S.WrapperContent>
        <S.WrapperSkipButton>
          <S.SkipButton onClick={() => setAlertModalVisible(true)}>
            Pular
          </S.SkipButton>
        </S.WrapperSkipButton>

          <S.WrapperTextInformation>
            {getOnboardingStep()}
          </S.WrapperTextInformation>

          <S.WrapperFooter>
            <ProgressBar
              steps={2}
              currentStep={step}
              setCurrentStep={setStep}
              endOnboarding={endOnboarding}
            />
          </S.WrapperFooter>
      </S.WrapperContent>
    </S.Container>
  );
}
